import * as React from "react";
import Layout from "./../../../components/fr/Layout";
import LadyPink from "./../../../nav/fr/Artistes/LadyPink";
import { Helmet } from "react-helmet";

const LadyPinkPage = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>LADY PINK | GHOST GALERIE</title>
        <meta
          name="description"
          content="GHOST galerie, Marseille. New York City : Post Graffiti • Old School | 80's & 90's"
        />
        <meta name="keywords" content="ghostgalerie,ghostgallery,ghostgalerieexposition,ghostgalerieexhibition,ghostparis,ghostmarseille,ghostartiste,ghostartists,ladypink,ladypinkgraffiti,ladypinkartist,ladypinkartiste,ladypinkwomenart,ladypinkgraffiti,ladypinkcontemporaryart,ladypinkartcontemporain,ladypinkavailablepainting,ladypinkpainting,ladypinkoeuvres,ladypinkartwork,ladypinkgallery,ladypinkgalerie,ladypinkbiographie,ladypinkbiography,ladypinktrain,ladypinksubwayart,sandrafabara,graffitiurbain,firstladyofgraffiti,womeningraffiti,graffitifeminin,ladypinkcollection,ladypinkmuseum,ladypinknyc,artnetgraffiti,artnetgalley,artnetgalerie,artsygallery,artsygalerie,graffitinewyorkais,womeninarts,powerwomen,ladypinkexposition,ladypinkexhibition,ladypinkmural,ladypinkarticle,ladypinkpress,ladypinkdessin,ladypinkartwork,americanartcollection,artcollection,googleart,googleartist,googlegallery"/>
      </Helmet>
      <LadyPink />
    </Layout>
  );
};

export default LadyPinkPage;
