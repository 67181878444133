// extracted by mini-css-extract-plugin
export var ArtistDescription = "LadyPink-module--ArtistDescription--eHs6T";
export var ArtistInfos = "LadyPink-module--ArtistInfos--qT8aN";
export var ButtonWrapper = "LadyPink-module--ButtonWrapper --D+JGq";
export var CardWrapper = "LadyPink-module--CardWrapper--w3jYA";
export var CarrouselWrapper2 = "LadyPink-module--CarrouselWrapper2--UY6dC";
export var Citations = "LadyPink-module--Citations--VY-4H";
export var DescriptionWrapper = "LadyPink-module--DescriptionWrapper--QThX7";
export var ImageWrapper = "LadyPink-module--ImageWrapper--6WpEk";
export var LinkWrapper = "LadyPink-module--LinkWrapper--cvv6Y";
export var MobileProtrait = "LadyPink-module--MobileProtrait--n7D0g";
export var More = "LadyPink-module--More--rZrPC";
export var MoreButton = "LadyPink-module--MoreButton--f0kN-";
export var NameWrapper = "LadyPink-module--NameWrapper--AWVwS";
export var PdpWrapper = "LadyPink-module--PdpWrapper--WS12M";
export var PhotosWrapper = "LadyPink-module--PhotosWrapper--eSJS4";
export var ProfilWrapper = "LadyPink-module--ProfilWrapper---0VHm";
export var TitleWrapper = "LadyPink-module--TitleWrapper--cj+Ns";
export var Wrapper = "LadyPink-module--Wrapper--QW3rX";